<template>
  <div id="form">
    <el-form
      :ref="formName"
      :model="query"
      :rules="queryRule"
      label-width="120px"
      label-position="left"
    >
      <el-form-item
        label="名称"
        prop="title"
      >
        <el-input v-model="query.title" />
      </el-form-item>
      <el-form-item
        label="安插平台"
        align="left"
        prop="device"
      >
        <el-select
          v-model="query.device"
          placeholder="请选择"
        >
          <el-option
            v-for="item in deviceOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="广告位置"
        align="left"
        prop="position_id"
      >
        <el-select
          v-model="query.position_id"
          placeholder="请选择"
        >
          <el-option
            v-for="item in positionList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="连结地址"
        prop="href"
      >
        <el-input
          v-model="query.href"
          placeholder="https://"
        />
      </el-form-item>
      <el-form-item
        label="开始时间"
        required
      >
        <el-col :span="7">
          <el-form-item prop="begin_at">
            <el-date-picker
              v-model="query.begin_at"
              type="datetime"
              placeholder="选择开始时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="left"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="2">至</el-col>
        <el-col :span="7">
          <el-form-item prop="end_at">
            <el-date-picker
              v-model="query.end_at"
              type="datetime"
              placeholder="选择結束时间"
              value-format="yyyy-MM-dd HH:mm:ss"
              align="left"
              style="width: 100%"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-form-item>
      <el-form-item
        label="排序"
        prop="sort"
        align="left"
      >
        <el-input-number
          style="width: 200px;"
          :min="1"
          v-model="query.sort"
        />
      </el-form-item>
      <el-form-item
        label="广告图示"
        prop="upload"
        ref="uploadValidate"
      >
        <template>
          <div class="upload-colum">
            <el-upload
              class="upload-demo"
              ref="refPhoto"
              action=""
              :on-remove="handleRemovePhoto"
              :on-change="handlePhoto"
              accept="image/jpg,image/png,image/jpeg"
              :file-list="photoList"
              :disabled="photoBtnDisable"
              list-type="picture"
              :auto-upload="false"
              :multiple="false"
              :limit="1"
            >
              <el-button
                size="medium"
                type="primary"
                :disabled="photoBtnDisable"
              >
                <i class="el-icon-upload el-icon--right"></i> 上传图片
              </el-button>
              <div
                slot="tip"
                class="el-upload__tip"
              >
                ＊限制上傳 1 張照片，支持 jpg, png, jpeg 格式
              </div>
              <div
                slot="file"
                slot-scope="{file}"
              >
                <img
                  class="el-upload-list__item-thumbnail"
                  :src="file.url"
                  alt=""
                >
                <a class="el-upload-list__item-name">
                  {{file.name}}
                </a>
                <label class="el-upload-list__item-status-label">
                  <i class="el-icon-upload-success el-icon-check"></i>
                </label>
                <i
                  class="el-icon-close"
                  @click="handleRemovePhoto"
                ></i>
              </div>
            </el-upload>
          </div>
        </template>
      </el-form-item>
      <el-form-item
        label="备注"
        prop="remark"
      >
        <el-input
          maxlength="200"
          show-word-limit
          type="textarea"
          :autosize="{ minRows: 3, maxRows: 6 }"
          v-model="query.remark"
        />
      </el-form-item>
    </el-form>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleCancel">
        取消
      </el-button>
      <el-button
        type="primary"
        :loading="saveLoading"
        @click="handleSubmit"
      >
        确认
      </el-button>
    </div>
  </div>
</template>

<script>
import resStatus from '@/constants/resStatus'
import { promoteAdd, promotePositionList } from '@/api/admin/promote'

export default {
  props: {
    deviceOptions: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      formName: 'form',
      photoList: [],
      photoBtnDisable: false,
      saveLoading: false,
      positionList: [],
      query: {
        title: '',
        sort: '',
        begin_at: '',
        end_at: '',
        remark: '',
        is_visible: 1,
        upload: null,
        href: null,
        position_id: null
      },
      queryRule: {
        title: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        device: [
          {
            required: true,
            message: '请选择安插平台',
            trigger: 'blur'
          }
        ],
        href: [
          {
            required: true,
            message: '请输入连结地址',
            trigger: 'blur'
          }
        ],
        sort: [
          {
            required: true,
            message: '请输入排序數字',
            trigger: 'blur'
          }
        ],
        begin_at: [
          {
            required: true,
            message: '请选择开始时间',
            trigger: 'blur'
          }
        ],
        end_at: [
          {
            required: true,
            message: '请选择结束时间',
            trigger: 'blur'
          }
        ],
        remark: [
          {
            required: true,
            message: '请输入备注',
            trigger: 'blur'
          }
        ],
        upload: [
          {
            required: true,
            validator: this.validatePhoto,
            trigger: 'blur'
          }
        ],
        position_id: [
          {
            required: true,
            message: '请选择广告位置',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created () {
    this.query.random_dirname = 'rand' + Math.floor(Math.random() * 100000)
    this.init()
  },
  methods: {
    handleRemovePhoto (file, fileList) {
      this.query.upload = null
      this.photoList.pop()
      this.$refs.refPhoto.clearFiles()
      this.photoBtnDisable = false
    },
    handlePhoto (file) {
      if (this.query.upload) {
        this.$message.error('最多上传一张图片！')
        return
      }
      this.varifyPhotoMeasure(file).then(obj => {
        if (obj) {
          if (obj.message) {
            this.$message.error(obj.message)
            this.$refs.refPhoto.clearFiles()
          } else {
            this.query.upload = file.raw
            this.photoBtnDisable = true
            this.$refs.uploadValidate.clearValidate()
          }
        } else {
          this.$message.error('发生错误')
        }
      })
    },
    handleSubmit () {
      this.$refs[this.formName].validate(valid => {
        if (valid) { this.saveData() }
      })
    },
    handleCancel () {
      this.$router.go(-1)
    },
    saveData () {
      this.saveLoading = true
      promoteAdd(this.query)
        .then(res => {
          this.saveLoading = false
          switch (res.status) {
            case resStatus.OK:
              this.$router.go(-1)
              break
          }
          if (res.message.length !== 0) {
            this.$message(res.message)
          }
        })
        .catch(err => {
          this.saveLoading = false
          this.$message.error(err)
        })
    },
    varifyPhotoMeasure (file) {
      const filePath = file.url
      // var width = 0
      // var height = 0
      if (filePath) {
        const image = new Image()
        image.src = window.URL.createObjectURL(file.raw)
        return new Promise(resolve => {
          image.onload = function () {
            // width = this.width
            // height = this.height
            let resObj = {}
            // if (width !== 135 && height !== 110) {
            //   resObj = { message: '图片尺寸应为：135*110' }
            // } else {
            //   resObj = { file: file }
            // }
            resObj = { file: file }
            resolve(resObj)
          }
        })
      } else {
        return { message: '上传失敗' }
      }
    },
    validatePhoto (rule, value, callback) {
      if (!this.query.upload) {
        callback(new Error('请上傳广告图示'))
      } else {
        callback()
      }
    },
    init () {
      promotePositionList({
        page: 1,
        pageSize: 1000
      }).then(res => {
        switch (res.status) {
          case resStatus.OK:
            var newArr = res.data.map(res => {
              return { value: res.id, label: res.name }
            })
            this.positionList = newArr
            break
          default:
            this.$message.error(res.message)
        }
      }).catch(err => {
        this.$message.error(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#form {
  max-width: calc((100vw - 200px) * 0.8);
  .effect-time-colum {
    text-align: left;
    & span {
      display: inline-block;
      margin: 0 10px;
    }
  }
  .upload-colum {
    text-align: left;
    .el-upload-list--picture
      .el-upload-list__item.is-ready
      .el-upload-list__item-name {
      line-height: 70px;
      margin-top: 0;
    }
    .el-upload-list__item.is-ready .el-upload-list__item-status-label {
      display: block;
    }
  }
}
.el-input-number {
  width: 100%;
}
</style>
